import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import { getCourses } from "../actions/actions";
import { CropLandscapeOutlined } from "@mui/icons-material";

export const GlobalContext = createContext();

export const ContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let response = await getCourses();
      if (response?.result) {
        response.data.forEach((item, index) => {
          const values = getNumericValuesFromStream(item.mode);
          response.data[index].mode = {
            honduras: {
              faceToFace: values[0],
              online: values[1],
            },
            panama: {
              faceToFace: values[4],
              online: values[5],
            },
          };
        });
        console.log(response.data);
        setCourses(response.data);
      }
      setIsLoading(false);
    };
    getData();
  }, []);

  const getNumericValuesFromStream = (string) => {
    const numericValues = [];

    // Eliminar las comillas y los dos puntos de la cadena JSON
    const sanitizedData = string.replace(/"|:/g, "");

    // Encontrar todos los valores numéricos en la cadena
    const regex = /\d+/g;
    let match;
    while ((match = regex.exec(sanitizedData))) {
      const numericValue = parseInt(match[0]);
      numericValues.push(numericValue);
    }
    return numericValues;
  };

  const loggin = () => {
    localStorage.setItem("loggin", JSON.stringify(true));
    setIsLogged(true);
  };

  const loggout = () => {
    localStorage.setItem("loggin", JSON.stringify(false));
    setIsLogged(false);
  };

  const checkIfLogged = () => {
    const logged = JSON.parse(localStorage.getItem("loggin"));
    if (!logged) return;
    return setIsLogged(logged);
  };

  return (
    <GlobalContext.Provider
      value={{
        courses,
        setCourses,
        isLoading,
        loggin,
        isLogged,
        checkIfLogged,
        loggout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
