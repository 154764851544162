import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useContext, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import EmailForm from "../components/common/EmailForm";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import BackComponent from "../components/common/BackComponent";

const SignMeUpPage = () => {
  //const router = useRouter();
  //const { start, end, name } = router.query;
  const location = useLocation();
  const { start, end, title } = location.state;
  const { courses } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [initialCourseMsg, setInitialCourseMsg] = useState("");
  const [message, setMessage] = useState("");
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    setData(courses);
  }, [courses]);

  useEffect(() => {
    let msg = `I am interested in the course ${title} with the start date ${start} and the end date ${end}`;
    setInitialCourseMsg(msg);
    setMessage(msg);
  }, [start, end, title]);

  useEffect(() => {
    if (!filterValue) {
      setData(courses);
    }
  }, [filterValue, courses]);

  const columns = [
    {
      field: "title",
      headerName: "Course name",
      width: 800,
    },
    { field: "id", headerName: "ID", width: 20, hide: true },
  ];

  const handleCourseSelection = (id) => {
    let newValue = "";
    console.log("this", id);
    id.forEach((item) => {
      courses.forEach((cours) => {
        if (item == cours.id) {
          newValue = newValue + `${cours.id} - ${cours.title}.\n `;
        }
      });
    });
    setMessage(
      `${initialCourseMsg} ${
        newValue
          ? `.\n\n And would love to know more about these ones too:\n ${newValue}`
          : "."
      }`
    );
  };

  const handleSearch = () => {
    let newArray = courses.filter((item) => {
      return filterValue.toLocaleLowerCase() === ""
        ? item
        : item.title.toLocaleLowerCase().includes(filterValue);
    });
    setData(newArray);
  };

  return (
    <Container>
      <BackComponent />
      <Box sx={{ textAlign: "center", my: 4 }}>
        <Typography variant="h5" color="initial" sx={{ textTransform: "bold" }}>
          {title}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" color="initial">
            Starting date: {start}
          </Typography>
          <Typography variant="body1" color="initial">
            Ending date: {end}
          </Typography>
          <EmailForm message={message} disabled={true} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" color="initial" sx={{ mb: 1 }}>
            Other courses that might interest you
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3, mt: 2.5 }}>
            <TextField
              fullWidth
              label="Start to type."
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
                if (filterValue) {
                  handleSearch();
                }
              }}
            />
          </Box>
          <Box sx={{ height: 650 }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={10}
              checkboxSelection={true}
              onRowSelectionModelChange={(model) => {
                handleCourseSelection(model);
              }}
              rowsPerPageOptions={[5]}
              experimentalFeatures={{ newEditingApi: true }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 100 },
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignMeUpPage;
