import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { EMPTY_FILTERS, SERVER } from "../utils/constants";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CircularProgress from "@mui/material/CircularProgress";
import { GlobalContext } from "../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import BackComponent from "../components/common/BackComponent";
import { ToastContainer, toast } from "react-toastify";

const SearchCourses = () => {
  const { courses, isLoading } = useContext(GlobalContext);
  const [textInput, setTextInput] = useState("");
  const [filters, setFilters] = useState(EMPTY_FILTERS);
  const navigate = useNavigate();
  const ref = useRef(false);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const CountrySelect = () => {
    return (
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filters.country}
        name="country"
        label="Country"
        sx={{ minWidth: 200 }}
        fullWidth
        onChange={(e) => {
          if (e.target.value === "Country") {
            ref.current = true;
            setFilters({ ...filters, mode: "Mode" });
            return;
          }
          handleFilterChange(e);
        }}
      >
        <MenuItem value={"Country"}>Country</MenuItem>
        <MenuItem value={"honduras"}>HONDURAS</MenuItem>
        <MenuItem value={"panama"}>PANAMA</MenuItem>
      </Select>
    );
  };

  useEffect(() => {
    if (ref) {
      setFilters({ ...filters, country: "Country" });
      ref.current = false;
    }
  }, [ref.current]);

  const CoursesSelect = () => {
    let coursesFamlylies = [
      "Family",
      "BASIC SAFETY COURSE",
      "COMPETENCY CERTIFICATES SAFETY COURSE",
      "OIL AND CHEMICAL TANKER AND GAS CARRIER SPECIAL COURSES",
      "RO-RO AND PASSENGER SHIPS SAFETY COURSES",
      "SECURITY AND SAFETY COURSES",
    ];
    return (
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filters.courses}
        name="courses"
        label="courses"
        sx={{ minWidth: 200 }}
        fullWidth
        onChange={(e) => handleFilterChange(e)}
      >
        {coursesFamlylies.map((item, index) => {
          return (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const handleDownloadPdf = async (url) => {
    window.open(`${SERVER}/api/download-pdf?pdfID=${url}`);
  };

  const handleClearFilters = () => {
    setFilters(EMPTY_FILTERS);
    setTextInput("");
  };

  const ModeSelect = () => {
    return (
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={filters.mode}
        name="mode"
        label="mode"
        fullWidth
        sx={{ minWidth: 200 }}
        onChange={(e) => {
          if (filters.country.toUpperCase() === "Country".toUpperCase()) {
            return toast.warn("You need to specify a country first.");
          }
          handleFilterChange(e);
        }}
      >
        <MenuItem value={"Mode"}>Mode</MenuItem>
        <MenuItem value={"faceToFace"}>FACE TO FACE</MenuItem>
        <MenuItem value={"online"}>ON BOARD</MenuItem>
      </Select>
    );
  };

  return (
    <>
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
            py: 3,
            width: "100%",
          }}
        >
          <BackComponent />
          <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
            <img
              src={"./logos.webp"}
              alt="logo"
              style={{ width: "auto", height: 200 }}
            />
          </Box>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            FIND A COURSE
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            Use keywords to find the course you are interested in, then click to
            find out when we are holding classes.
          </Typography>
        </Box>
        {/* End hero unit */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            gap: 2,
            justifyContent: "center",
            "@media (max-width: 768px)": {
              // Estilos para pantallas con un ancho mínimo de 768px
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <CoursesSelect />
          <CountrySelect />
          <ModeSelect />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <TextField
            fullWidth
            value={textInput}
            label="Start to type."
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            onChange={(e) => setTextInput(e.target.value)}
          />
          <Button sx={{ width: 200 }} onClick={handleClearFilters}>
            <FilterAltOffIcon sx={{ mr: 1 }} />
            Clear filters
          </Button>
        </Box>

        <Grid container spacing={3}>
          {isLoading ? (
            <>
              <Box sx={{ textAlign: "center", width: "100%", mt: 10 }}>
                <CircularProgress />
              </Box>
            </>
          ) : (
            <>
              {courses
                .filter((item) => {
                  return filters.country === "Country"
                    ? item
                    : item["in_" + filters.country] === 1;
                })
                .filter((item) => {
                  return filters.courses === "Family"
                    ? item
                    : item.family?.toUpperCase() ===
                        filters.courses?.toUpperCase();
                })
                .filter((item) => {
                  return filters.mode === "Mode"
                    ? item
                    : item.mode[filters.country][filters.mode] === 1;
                })
                .filter((item) => {
                  return !textInput
                    ? item
                    : item.title
                        .toUpperCase()
                        ?.includes(textInput?.toUpperCase()) ||
                        item.description
                          .toUpperCase()
                          ?.includes(textInput?.toUpperCase());
                })
                .map((course, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={
                          course.url_image
                            ? `${SERVER}${course.url_image}`
                            : `${SERVER}/placeholder.png`
                        }
                        alt="random"
                        sx={{ height: 200, cursor: "pointer" }}
                        onClick={() => {
                          navigate("/details", {
                            state: { code: course.code },
                          });
                        }}
                      />
                      <CardContent sx={{ flexGrow: 1, height: 165 }}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            cursor: "pointer",
                          }}
                          component="h2"
                          className="textHeader"
                          onClick={() => {
                            console.log(course.code);
                            navigate("/details", {
                              state: { code: course.code },
                            });
                          }}
                        >
                          {course.title}
                        </Typography>
                        <Typography className="textBody">
                          {course.description}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          size="small"
                          onClick={() => {
                            console.log(course.code);
                            navigate("/details", {
                              state: { code: course.code },
                            });
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Details
                        </Button>
                        <Button
                          size="small"
                          disabled={!course.url_pdf}
                          onClick={() => handleDownloadPdf(course.url_pdf)}
                          variant="contained"
                        >
                          Print
                        </Button>
                        <Button
                          size="small"
                          onClick={() => navigate("/search-class")}
                          variant="contained"
                        >
                          Find class
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </main>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default SearchCourses;
