export const getSubscribeEmailTemplate = (name, message, email) => {
  let body = MESSAGE_BODY;
  body = body.replace("$name", name);
  body = body.replace("$message", message);
  body = body.replace("$email", email);
  return body;
};

const MESSAGE_BODY = `<!DOCTYPE html>
<html>
	<head>
		<meta charset="utf-8" />
		<style type="text/css">
			body {
				background-color: #f2f2f2;
			}
			.container {
				background-color: #ffffff;
				margin: 0 auto;
				max-width: 600px;
				padding: 20px;
				text-align: center;
			}
			.footer {
				background-color: #0062ad;
				color: #ffffff;
				font-size: 14px;
				padding: 10px;
				text-align: center;
				font-weight: bold;
			}
		</style>
	</head>
	<body>
		<div class="container">
			<img
				src="https://courses.mp-ip.edu.pa/logo.png"
				alt="image"
				class="desktop1-image" />
			<p>
			El usuario $name, ha enviado el siguiente mensaje: $message. Lo puede contactar a travez de: $email
			</p>
		</div>
	</body>
</html>`;
