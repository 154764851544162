import { SERVER } from "../utils/constants";

export const getCourses = async () => {
  const data = await fetch(`${SERVER}/api/getcourses`, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch((err) => console.log(err));
  return data;
};

export const sendEmail = async (body, subject, destination) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  fetch(
    `${SERVER}/api/sendemail?subject=${subject}&destination=${destination}&body=${encodeURIComponent(
      body
    )}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export const addCourse = async (data, imgFile, pdfFile) => {
  const formdata = new FormData();
  formdata.append("imgFile", data.imgFile);
  formdata.append("pdfFile", data.pdfFile);
  formdata.append("title", data.title);
  formdata.append("description", data.description);
  formdata.append("code", data.code);
  formdata.append("duration", data.duration);
  formdata.append("pre_requirement", data.pre_requirement);
  formdata.append("material_requirement", data.material_requirement);
  formdata.append("rules", data.rules);
  formdata.append("mode", data.mode);
  formdata.append("panama_resolution", data.panama_resolution);
  formdata.append("honduras_resolution", data.honduras_resolution);
  formdata.append("in_panama", data.in_panama);
  formdata.append("in_honduras", data.in_honduras);
  formdata.append("family", data.family);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  const response = await fetch(`${SERVER}/api/course`, requestOptions)
    .then((response) => response.json())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));

  return response;
};

export const deleteCourse = async (id) => {
  let response = "";
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };

  await fetch(`${SERVER}/api/course?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => (response = result))
    .catch((error) => console.log("error", error));

  return response;
};

export const updateCourse = async (data) => {
  let response = {};
  console.log(data);
  const formdata = new FormData();
  formdata.append("imgFile", data.imgFile);
  formdata.append("pdfFile", data.pdfFile);
  formdata.append("title", data.title);
  formdata.append("description", data.description);
  formdata.append("code", data.code);
  formdata.append("duration", data.duration);
  formdata.append("pre_requirement", data.pre_requirement);
  formdata.append("material_requirement", data.material_requirement);
  formdata.append("rules", data.rules);
  formdata.append("mode", data.mode);
  formdata.append("panama_resolution", data.panama_resolution);
  formdata.append("honduras_resolution", data.honduras_resolution);
  formdata.append("in_panama", data.in_panama);
  formdata.append("in_honduras", data.in_honduras);
  formdata.append("family", data.family);
  formdata.append("id", data.id);
  formdata.append("url_image", data.url_image);
  formdata.append("url_pdf", data.url_pdf);

  var requestOptions = {
    method: "PUT",
    body: formdata,
    redirect: "follow",
  };

  await fetch(`${SERVER}/api/course`, requestOptions)
    .then((response) => response.json())
    .then((result) => (response = result))
    .catch((error) => console.log("error", error));

  return response;
};

export const getPdfFromServer = async (pdfUrl) => {
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };

  const response = await fetch(
    `${SERVER}/api/download-pdf?pdfID=${pdfUrl}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));

  return response;
};
