export const SERVER = "https://mpip.mp-ip.edu.pa";
//export const SERVER = "http://localhost:3000";

export const EMPTY_FILTERS = {
  country: "Country",
  courses: "Family",
  mode: "Mode",
};

export const EMPTY_EMAIL_FORM = {
  name: "",
  email: "",
  subject: "Course request from the website",
  message: "",
};

export const EMPTY_COURSE = {
  title: "",
  id: "",
  description: "",
  code: "",
  duration: "",
  pre_requirement: "",
  material_requirement: "",
  rules: "",
  mode: "",
  panama_resolution: "",
  honduras_resolution: "",
  classes: [],
  in_panama: "",
  in_honduras: "",
  family: "",
  imgFile: "",
  pdfFile: "",
};

export const MAINTENANCE_PASSWORD = "123456";

export const LOGO =
  "https://web.mp-ip.edu.pa/wp-content/uploads/2022/05/2E37408E932444F9B4F6DCCDDCDFAE81.png";
