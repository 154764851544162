import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useRoutes,
} from "react-router-dom";
import SearchCourses from "./pages/SearchCourses";
import DetailsScreen from "./pages/DetailsScreen";
import { Container } from "@mui/material";
import SearchClass from "./pages/SearchClass";
import SignMeUpPage from "./pages/SignMeUpPage";

/* const App = () => {
  let routes = useRoutes([
    { path: "/", element: <SearchCourses /> },
    { path: "/details", element: <DetailsScreen /> },
    { path: "/search-class", element: <SearchClass /> },
    { path: "/signmeup", element: <SignMeUpPage /> },
    // ...
  ]);
  return routes;
}; */

const AppWrapper = () => {
  return (
    <Router /* basename={"/courses/courses-schedules/"} */>
      <Container sx={{ mb: 10 }}>
        <Routes>
          <Route path="/" element={<SearchCourses />} />
          <Route path="/details" element={<DetailsScreen />} />
          <Route path="/search-class" element={<SearchClass />} />
          <Route path="/signmeup" element={<SignMeUpPage />} />
        </Routes>
        {/* <App /> */}
      </Container>
    </Router>
  );
};

export default AppWrapper;
