import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import { GlobalContext } from "../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import BackComponent from "../components/common/BackComponent";
import { LOGO } from "../utils/constants";

const SearchClass = () => {
  const [value, setValue] = useState({ start: "", end: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [textInput, setTextInput] = useState("");
  const { courses } = useContext(GlobalContext);
  const [courseData, setCourseData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(12);
  const [currentEntries, setCurrentEntries] = useState([]);
  const [pageNumbers, setPageNumbers] = useState(1);

  const navigate = useNavigate();

  const handleChange = (newValue, input) => {
    setValue({ ...value, [input]: newValue });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (!data) return;
    let totalEntries = data.length;
    setPageNumbers(Math.ceil(totalEntries / postPerPage));
    const lastIndex = page * postPerPage;
    const firstIndex = lastIndex - postPerPage;
    setCurrentEntries(data.slice(firstIndex, lastIndex));
  }, [page, data]);

  useEffect(() => {
    let classesData = [];
    let courseList = [];
    courses.forEach((course) => {
      let classes = course.classes.map((item) => {
        return {
          ...item,
          course_title: course.title,
          course_date_end: moment(item.course_date_end).format("ll"),
          course_date_start: moment(item.course_date_start).format("ll"),
        };
      });
      courseList.push({
        title: course.title,
        id: course.id,
        isChecked: false,
        classes: course.classes,
      });
      classesData = [...classesData, ...classes];
    });
    setClasses(classesData);
    setData(classesData);
    setCourseData(courseList);
  }, [courses]);

  const columns = [
    {
      field: "course_title",
      headerName: "Class name",
      width: 300,
    },
    {
      field: "course_date_start",
      headerName: "Start date",
      width: 120,
    },
    {
      field: "course_date_end",
      headerName: "End date",
      width: 120,
    },
    {
      field: "rendered",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              color="error"
              sx={{ backgroundColor: "#ff061b" }}
              onClick={() => {
                navigate(`/signmeup`, {
                  state: {
                    start: params.row.course_date_start,
                    end: params.row.course_date_end,
                    title: params.row.title,
                  },
                });
              }}
            >
              Sign me up
            </Button>
          </>
        );
      },
    },
    { field: "course_id", headerName: "ID", width: 20, hide: true },
  ];

  useEffect(() => {
    handleFilters();
  }, [textInput, value.start, value.end, courseData]);

  const handleFilters = () => {
    setIsLoading(true);
    let initialData = [...classes];
    let filteredByCourse = handleSelectFilter(initialData);
    let filteredSearch = handleSearch(filteredByCourse);
    let filteredDate = handleDateFilter(filteredSearch);
    setData(filteredDate);
    setIsLoading(false);
  };

  const handleSelectFilter = (filtered) => {
    let finalData = [];
    let checkedCoursesId = [];
    courseData.forEach((item) => {
      if (item.isChecked === true) {
        checkedCoursesId.push(item.id);
      }
    });
    if (checkedCoursesId.length === 0) return filtered;
    checkedCoursesId.forEach((id) => {
      filtered.forEach((item) => {
        if (id === item.course_id) {
          finalData = [...finalData, item];
        }
      });
    });
    return finalData;
  };

  const handleSearch = (filtered) => {
    if (!textInput) return filtered;
    let newArray = filtered.filter((item) => {
      return textInput.toLocaleLowerCase() === ""
        ? item
        : item.course_title.toLowerCase().includes(textInput);
    });
    return newArray;
  };

  const handleDateFilter = (filtered) => {
    if (!value.start || !value.end) return filtered;
    let newArray = filtered.filter((item) => {
      return moment(item.course_date_start).isBetween(
        value.start.format("L"),
        value.end.format("L")
      );
    });
    return newArray;
  };

  const handleSelectChange = (id) => {
    let data = [...courseData];
    let newData = data.map((item) => {
      if (item.id === id) {
        return { ...item, isChecked: !item.isChecked };
      } else {
        return item;
      }
    });
    setCourseData(newData);
  };

  const handleClearFilters = () => {
    setTextInput("");
    setValue({ start: "", end: "" });
    let courseDataFalses = courseData.map((item) => {
      return { ...item, isChecked: false };
    });
    setCourseData(courseDataFalses);
  };

  return (
    <>
      <Container>
        <BackComponent />
        <Box
          sx={{
            py: 2,
            mb: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
            <img
              src={"./logos.webp"}
              alt="logo"
              style={{ width: "auto", height: 180 }}
            />
          </Box>
          <Typography variant="h2" color="initial" sx={{ textAlign: "center" }}>
            FIND A <span style={{ color: "#ff061b" }}>CLASS</span>
          </Typography>
          <Typography
            variant="h6"
            color="initial"
            sx={{ width: 400, my: 1, textAlign: "center", px: 1 }}
          >
            Use keywords to find the classes you are interested in, click to
            read details or sign up.
          </Typography>
          <Button variant="contained">FIND A COURSE</Button>
        </Box>
      </Container>
      <Container>
        <Grid container sx={{ pt: 2 }}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              value={textInput}
              label="Start to type and hit ENTER"
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              onChange={(e) => {
                setTextInput(e.target.value);
              }}
              sx={{ my: 1 }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                <DesktopDatePicker
                  name="start"
                  label="Start Date"
                  inputFormat="MM/DD/YYYY"
                  value={value.start}
                  onChange={(newValue) => {
                    handleChange(newValue, "start");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} error={false} value={value.start} />
                  )}
                />
                <DesktopDatePicker
                  name="end"
                  label="End Date"
                  inputFormat="MM/DD/YYYY"
                  value={value.end}
                  onChange={(newValue) => {
                    handleChange(newValue, "end");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} error={false} value={value.end} />
                  )}
                />
              </Box>
              <Button fullWidth onClick={handleClearFilters} sx={{ mb: 2 }}>
                <FilterAltOffIcon sx={{ mr: 1 }} />
                Clear filters
              </Button>
            </LocalizationProvider>
            <Typography variant="h6" color="initial">
              FIND A <span style={{ color: "#ff061b" }}>CLASS</span>
            </Typography>
            {/* COURSES LIST */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {courseData.map((course, index) => {
                return (
                  <Box
                    key={index}
                    sx={{ display: "flex", my: -1, alignItems: "baseline" }}
                  >
                    <Checkbox
                      checked={course.isChecked}
                      onChange={() => handleSelectChange(course.id)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ position: "relative", top: 8 }}
                    />
                    <Typography color="initial" sx={{ fontSize: 12 }}>
                      {course.title?.toUpperCase()}
                    </Typography>
                    <Typography
                      color="initial"
                      sx={{
                        fontSize: 12,
                        backgroundColor: "lightGray",
                        px: 1,
                        borderRadius: 10,
                        ml: 0.5,
                        height: 18,
                      }}
                    >
                      {course.classes.length}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{ height: 1000, ml: 3 }}>
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 30,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      count={pageNumbers}
                      page={page}
                      onChange={handlePageChange}
                      sx={{ mx: "auto", mb: 2 }}
                    />
                  </Box>
                  {currentEntries.length < 1 && (
                    <>
                      <Typography variant="h6" color="initial">
                        No results found with these specifications.
                      </Typography>
                    </>
                  )}
                  {currentEntries.slice(0, 20).map((item, index) => {
                    return (
                      <Box key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            my: 1,
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="initial"
                            sx={{ width: "40%" }}
                          >
                            {item.course_title.toUpperCase()}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="initial"
                            sx={{ width: "20%" }}
                          >
                            {item.course_date_start}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="initial"
                            sx={{ width: "20%" }}
                          >
                            {item.course_date_end}
                          </Typography>
                          <Button
                            variant="contained"
                            color="error"
                            sx={{ backgroundColor: "#ff061b", width: "20%" }}
                            onClick={() => {
                              navigate(`/signmeup`, {
                                state: {
                                  start: item.course_date_start,
                                  end: item.course_date_end,
                                  title: item.title,
                                },
                              });
                            }}
                          >
                            Sign me up
                          </Button>
                        </Box>
                        <Divider />
                      </Box>
                    );
                  })}
                  {/*  <DataGrid
                    rows={data}
                    columns={columns}
                    pageSize={30}
                    rowsPerPageOptions={[5]}
                  /> */}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SearchClass;
