import { Box, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

const BackComponent = () => {
  const navigate = useNavigate();
  return (
    <Box
      color="primary"
      sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={() => navigate(-1)}
    >
      <ArrowBackIosIcon />
      <Typography variant="h6" color="initial">
        Go Back
      </Typography>
    </Box>
  );
};

export default BackComponent;
