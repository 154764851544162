import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { EMPTY_EMAIL_FORM } from "../../utils/constants";
import { toast, ToastContainer } from "react-toastify";
import { sendEmail } from "../../actions/actions";
import { getSubscribeEmailTemplate } from "../../utils/emailTemplates";

const EmailForm = ({ message, disabled }) => {
  const [emailForm, setEmailForm] = useState(EMPTY_EMAIL_FORM);
  const [formError, setFormError] = useState(false);

  const handleEmailForm = (e) => {
    setFormError(false);
    setEmailForm({ ...emailForm, [e.target.name]: e.target.value });
  };

  const handleSendEmaiil = () => {
    if (!emailForm.name || !message || !emailForm.subject || !emailForm.email) {
      setFormError(true);
      toast.error(
        "Please provide all the required inputs in order to send us an email."
      );
      return;
    }
    sendEmail(
      getSubscribeEmailTemplate(emailForm.name, message, emailForm.email),
      emailForm.subject,
      "maritimecenter@mp-ip.edu.pa"
    );
    setEmailForm(EMPTY_EMAIL_FORM);
    toast.success("Email sent successfully.");
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        variant="body1"
        color="initial"
        sx={{ fontWeight: "bold", mt: 2, mb: 1 }}
      >
        Questions? Just Ask!
      </Typography>
      <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
        <TextField
          error={formError}
          name="name"
          value={emailForm.name}
          onChange={handleEmailForm}
          fullWidth
          label="Name*"
        />
        <TextField
          error={formError}
          fullWidth
          label="Email*"
          name="email"
          value={emailForm.email}
          onChange={handleEmailForm}
        />
      </Box>
      <TextField
        error={formError}
        id="outlined-multiline-static"
        label="Message*"
        name="message"
        value={message}
        onChange={handleEmailForm}
        multiline
        rows={10}
        fullWidth
        disabled={disabled}
        sx={{ mt: 2 }}
      />
      <Button onClick={handleSendEmaiil} variant="contained" sx={{ mt: 2 }}>
        Send message
      </Button>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
};

export default EmailForm;
